import React from "react"
import LayoutA7 from "../../components/layout-a7"
import enrollStyles from './a7_enroll.module.css'
import { navigate } from "@reach/router"

export default  class A7Enroll extends React.Component {

  chooseEvent=()=>{
    navigate('/demos/themeA7/enrollStep')
  }



  render() {
    const  raceDetail={
      raceName:'2019苏州簇格马拉松',
      poster:'../../images/a2/3.png',
      startTime:'2019-08-16 10:00:00',
      endTime:'2019-08-16 10:00:00',
      raceTime:'2019-08-16 10:00:00',
      location:'苏州市工业园区',
      events:[{name:"全程马拉松",price:150,tip:"年龄限制：1990年10月20日之前出生"},
        {name:"半程马拉松",price:150,tip:"年龄限制：1990年10月20日之前出生"},
        {name:"迷你马拉松",price:150,tip:"年龄限制：1990年10月20日之前出生"}]

    }
    const color='#00D4CE'
    return(
      <LayoutA7 >
        <div className={enrollStyles.enrollBody}>
          <div className={enrollStyles.enrollContainer}>
            <div className={enrollStyles.raceInfoContainer}>
              <div className={enrollStyles.racePoster}>
                <img alt="hello" src={require('../../images/a2/3.png')}/>
              </div>
              <div className={enrollStyles.raceInfo}>
                <div className={enrollStyles.raceTitle}>{raceDetail.raceName}</div>
                <div className={enrollStyles.startTime}>报名开始：{raceDetail.startTime}</div>
                <div className={enrollStyles.endTime}>报名截止：{raceDetail.endTime}</div>
                <div className={enrollStyles.raceTime}>比赛开始：{raceDetail.raceTime}</div>
                <div className={enrollStyles.raceLocation}>赛事地点：{raceDetail.location}</div>
              </div>
            </div>

            <div className={enrollStyles.eventsContainer}>
              <ul className="grid3 slide-up animated">
                {raceDetail.events.map((event,index)=><li  className={enrollStyles.eventItem} key={index} onClick={this.chooseEvent}>
                  <div className={enrollStyles.eventItemPc}>
                    <div className={enrollStyles.eventNameBg}></div>
                    <div className={enrollStyles.eventName}>{event.name}</div>
                    <div className={enrollStyles.eventPrice} >报名费：￥{event.price}</div>
                    <div className={enrollStyles.eventTip}>{event.tip}</div>
                    <div className={enrollStyles.eventEnrollBtn}>
                      <div className={enrollStyles.btnText}>立即报名</div>
                    </div>
                  </div>
                  <div className={enrollStyles.eventItemPhone}>
                    <div className={enrollStyles.eventShadow} style={{background:color}}></div>
                    <div className={enrollStyles.nameTip}>
                      <div className={enrollStyles.eventName}>{event.name}</div>
                      <div className={enrollStyles.eventTip}>{event.tip}</div>
                    </div>

                    <div className={enrollStyles.eventPrice} style={{color:color}}>￥{event.price}</div>


                  </div>
                </li>)}
              </ul>
            </div>

          </div>

        </div>
      </LayoutA7>
    )
  }
}